import { APIClient } from "ig-trading-api";
import { getStorageData, setStorageData } from "./storage";

const lightstreamer = require("lightstreamer-client-node");

const en_it = { gen: "Gen", feb: "Feb", mar: "Mar", apr: "Apr", may: "Mag", jun: "Giu", jul: "Lug", aug: "Ago", sep: "Set", oct: "Ott", nov: "Nov", dec: "Dic" };

export default class IG {
  urlRoot = "https://production-api.ig.com/gateway/deal";
  client = null;
  connected = false;
  accountId = null;
  account_token = null;
  refresh_token = null;
  client_token = null;
  lsEndpoint = null;
  lsClient;
  ticketSubscription;
  accountSubscription;

  constructor() {}

  saveData = res => {
    this.accountId = res.accountId;
    this.clientId = res.clientId;
    this.lightstreamerEndpoint = res.lightstreamerEndpoint;
    this.account_token = res.oauthToken.access_token;
    this.refresh_token = res.oauthToken.refresh_token;
    this.cst = this.client.rest.login.auth.clientSessionToken;
    this.token = this.client.rest.login.auth.securityToken;
    this.connected = true;
  };

  async connect(cred) {
    this.client = new APIClient(APIClient.URL_LIVE, cred.api);
    this.session = await this.client.rest.login.createSession(cred.username, cred.password).then(res => {
      this.saveData(res);
      return res;
    });
    return this.session;
  }

  isConnected() {
    return this.connected;
  }

  setAccount(accountId) {
    this.accountId = accountId;
    return this;
  }

  async getAccounts() {
    try {
      return await this.client.rest.account.getAccounts();
    } catch (err) {
      console.error(err);
      this.error = true;
      return false;
    }
  }

  getRequestParam(name) {
    if ((name = new RegExp("[?&]" + encodeURIComponent(name) + "=([^&]*)").exec(window.location.search))) return decodeURIComponent(name[1]);
  }

  async connectSocket(actId = null) {
    if (!this.connected) return [];
    this.error = false;
    if (!this.session) return;
    if (this.lsClient) return this.lsClient;
    try {
      console.log("CST-" + this.cst + "|XST-" + this.token);
      console.log("this.session", this.session);
      if (!this.session.lightstreamerEndpoint || !this.accountId) return false;
      const lsClient = new lightstreamer.LightstreamerClient(this.session.lightstreamerEndpoint);
      lsClient.connectionDetails.setUser(actId || this.accountId);
      lsClient.connectionDetails.setPassword("CST-" + this.cst + "|XST-" + this.token);
      lsClient.addListener({
        onListenStart: function() {
          console.log("ListenStart");
        },
        onStatusChange: function(status) {
          console.log("Lightstreamer connection status:" + status);
        }
      });
      this.lsClient = lsClient;
    } catch (err) {
      this.error = true;
      console.error("connectSocket", err);
    }
  }

  getPositions = async () => {
    if (!this.connected) return [];
    return await this.client.rest.dealing.getAllOpenPositions();
  };

  extractMarkets = epics => {
    return [...epics.map(el => (el.indexOf("SPX") > -1 ? process.env.REACT_APP_SP500_NODE : process.env.REACT_APP_EU50_NODE))];
  };

  sleep = milliseconds => {
    const date = Date.now();
    let currentDate = null;
    do {
      currentDate = Date.now();
    } while (currentDate - date < milliseconds);
  };

  traverse = async (nodeid, markets = [], delay = 20) => {
    const level = await this.client.rest.market.getMarketCategories(nodeid);
    console.log(level);
    this.sleep(2000);
    if (level.nodes) level.nodes.map(el => this.traverse(el.id, markets));
    if (level.markets) level.markets.map(el => markets.append(el));

    return markets;
  };

  getSocketData = async (id, epicsObjects, callbackAccount, callbackOpenPosition, callbackEpic) => {
    if (!this.connected) return [];

    this.error = false;
    try {
      await this.connectSocket(id);
      if (!this.lsClient) return;

      const epics = epicsObjects.map(el => el.epic);
      const markets = [];
      epicsObjects.map(async _epic => {
        for (let i = Number(_epic.target) - 200; i < Number(_epic.target) + 400; i += 10) {
          const tmp = _epic.epic.split(".");
          tmp[3] = `${i}P`;
          markets.push(tmp.join("."));
        }
      });

      const subscriptionAccount = new lightstreamer.Subscription("MERGE", "ACCOUNT:" + id, ["FUNDS", "MARGIN", "EQUITY", "PNL"]);
      subscriptionAccount.addListener({ onItemUpdate: callbackAccount });
      this.lsClient.subscribe(subscriptionAccount);

      epics.map(el => {
        const subscriptionPosition = new lightstreamer.Subscription("MERGE", "CHART:" + el + ":SECOND", ["DAY_NET_CHG_MID", "OFR_CLOSE"]);
        subscriptionPosition.addListener({ onItemUpdate: callbackOpenPosition });
        this.lsClient.subscribe(subscriptionPosition);
      });

      [...markets].map(el => {
        const subscriptionEpic = new lightstreamer.Subscription("DISTINCT", "CHART:" + el + ":TICK", ["BID", "OFR"]);
        subscriptionEpic.addListener({ onItemUpdate: callbackEpic });
        this.lsClient.subscribe(subscriptionEpic);
      });

      this.lsClient.connect();
    } catch (err) {
      this.error = true;
      console.error("_socket", err);
    }
  };
}
