import { Grid, MenuItem, Select, Table, TableBody, TableCell, TableRow } from "@mui/material";
import React, { useEffect, useState } from "react";
import Num, { addCommas } from "./Num";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleArrowDown, faCircleArrowUp, faCrosshairs, faDollarSign, faEuroSign, faHashtag } from "@fortawesome/free-solid-svg-icons";

export default function Left({ ig, accounts, data, positions, instruments }) {
  return (
    <Grid container direction={"column"} width={"50%"}>
      {accounts && accounts.length > 0 && (
        <Grid item margin={"10px 0"}>
          <Select defaultValue={data.accountId} value={data.accountId} onChange={e => ig.setAccount(e.target.value)}>
            {accounts.map(el => (
              <MenuItem value={el.accountId} key={el.accountId} ariaChecked={el.accountId == data.accountId}>
                {el.accountName} ( {el.balance.balance}
                {el.currency} )
              </MenuItem>
            ))}
          </Select>
        </Grid>
      )}
      <Grid item>
        <Grid container className="well">
          <Grid item style={{ width: "calc(100% - 180px)", display: "flex", justifyContent: "center" }}>
            <Num num={data.funds.pnl} className="big green" />
          </Grid>
          <Grid item>
            <Grid container flexDirection={"column"} className="right">
              <Grid item>
                <label>Cash:</label> <Num num={data.funds.cash} />
              </Grid>
              <Grid item>
                <label>Margin:</label> <Num num={data.funds.margin} />
              </Grid>
              <Grid item>
                <label>Valore:</label>
                <Num num={data.funds.equity - data.funds.cash} />
              </Grid>
              <Grid item>
                <label>Saldo:</label> <Num num={data.funds.equity} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <div className="positions well">
        {positions.map((el, i) => (
          <Grid container flex flexDirection={"row"} gap={10} key={i}>
            <label>
              <FontAwesomeIcon icon={faCrosshairs} /> {el.market.instrumentName}
            </label>
            <span>
              <FontAwesomeIcon icon={faHashtag} />
              <Num num={el.position.size} canInt />
            </span>
            <span>
              <FontAwesomeIcon icon={faCircleArrowDown} />
              <Num num={el.position.level} />
            </span>
            <span>
              <FontAwesomeIcon icon={faCircleArrowUp} />
              <Num num={el.market.offer} />
            </span>
            <span>
              <FontAwesomeIcon icon={el.position.currency == "USD" ? faDollarSign : faEuroSign} />
              <Num num={el.position.size * (Number(el.position.level) - Number(el.market.offer))} />
            </span>
            <span style={{ flexDirection: "column" }}>
              <span>
                <Num num={((el.market.netChange - el.market.target) / el.market.netChange) * -100} inverse />%
              </span>
              <label>{addCommas(el.market.netChange)}</label>
            </span>
          </Grid>
        ))}
      </div>
      <Grid container style={{ marginTop: 20 }} gap={10}>
        <Grid item width={"50%"}>
          <Table>
            <TableBody>
              {instruments.map((el, i) => {
                if (i < instruments.length - 10) return <></>;
                return (
                  <TableRow>
                    <TableCell>
                      <label>{el.target}</label>
                    </TableCell>
                    <TableCell>
                      <label>{(((positions[0].market.netChange - el.target) / positions[0].market.netChange) * -100).toFixed(2).replace(".", ",")}%</label>
                    </TableCell>
                    <TableCell>
                      <label>{el.bid}</label>
                    </TableCell>
                    <TableCell>
                      <label>{el.offer}</label>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </Grid>
      </Grid>
    </Grid>
  );
}
