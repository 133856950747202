import React from "react";

export function addCommas(nStr) {
  nStr += "";
  var x = nStr.split(".");
  var x1 = x[0];
  var x2 = x.length > 1 ? "," + x[1] : "";
  var rgx = /(\d+)(\d{3})/;
  while (rgx.test(x1)) {
    x1 = x1.replace(rgx, "$1" + "." + "$2");
  }
  return x1 + x2;
}

export default function Num({ num, canInt = false, inverse = false, className = null }) {
  const n = Number(num)
    .toFixed(2)
    .split(".");

  return (
    <span className={`number ${className || ""} ${n[0] < 0 ? (inverse ? "positive" : "negative") : inverse ? "negative" : "positive"}`}>
      <span className="int">{addCommas(n[0])}</span>
      {Number(n[1]) > 0 && !canInt && <span className="dec">,{n[1]}</span>}
    </span>
  );
}
