import "./App.css";
import React, { useEffect, useState } from "react";
import Ig from "./lib/ig";
import Settings from "./components/Settings";
import { getStorageData, setStorageData, getSettings } from "./lib/storage";
import Left from "./components/Left";
require("dotenv").config();

function App() {
  const [ig, setIg] = useState(new Ig());
  const [connected, setConnected] = useState(false);
  const [accounts, setAccounts] = useState([]);
  const [positions, setPositions] = useState([]);
  const [data, setData] = useState({
    accountId: null,
    funds: { cash: 0, margin: 0, equity: 0, pnl: 0 },
    positions: []
  });

  const [settings, setSettings] = useState({
    username: "",
    password: "",
    api: ""
  });

  const [instruments, setInstruments] = useState([]);

  useEffect(() => {
    console.log(instruments);
  }, [instruments]);
  useEffect(() => {
    const tmp = getSettings();
    const act = getStorageData("account");
    if (!tmp) return;
    setSettings(tmp);
    ig.connect(tmp).then(res => {
      setData({
        ...data,
        accountId: act || res.accountId
      });
      setConnected(true);
    });
  }, []);

  useEffect(() => {
    if (!connected) return;
    console.log("connected", connected);
    ig.getAccounts().then(res => setAccounts(res.accounts));
  }, [connected]);

  useEffect(() => {
    if (!data.accountId) return;
    setStorageData("account", data.accountId);
    if (!data.accountId || !connected || !ig.cst || !ig.client) return;

    //		ig.getOpenPositions(data.accountId, console.log);
    const fetchData = async () => {
      const __positions = await ig.getPositions();
      setPositions(
        __positions.positions.map(el => {
          el.market.target = el.market.epic
            .split(".")[3]
            .replace("P", "")
            .replace("C", "");
          return el;
        })
      );
      const epics = __positions.positions.map(el => ({ epic: el.market.epic, exp: el.market.expiry, target: el.market.target, node: el.market.epic.indexOf("SPX") > -1 ? process.env.REACT_APP_SP500_NODE : process.env.REACT_APP_EU50_NODE }));

      const callbackAccount = res => {
        setData({
          ...data,
          funds: {
            cash: res.updateValues[2],
            margin: res.updateValues[3],
            equity: res.updateValues[4],
            pnl: res.updateValues[5]
          }
        });
      };

      const callbackOpenPosition = res => {
        if (!__positions.positions || !__positions.positions.length) return;
        const epic = res.itemName.split(":")[1];
        const found = __positions.positions.map((el, index) => {
          if (el.market.epic == epic) {
            el.market.offer = Number(res.updateValues[3]);
            el.market.netChange = Number(res.updateValues[2]);
            __positions.positions[index] = el;
          }
        });
        setPositions(
          __positions.positions.map(el => {
            el.market.target = el.market.epic
              .split(".")[3]
              .replace("P", "")
              .replace("C", "");
            return el;
          })
        );
      };
      const _tmpArray = [];
      const callbackEpic = epic => {
        const _epic = epic.itemName.split(":")[1];
        const target = _epic
          .split(".")[3]
          .replace("P", "")
          .replace("C", "");
        const val = { bid: epic.updateValues[2], offer: epic.updateValues[3], target: target };

        const idx = _tmpArray.findIndex(el => el.target == target);
        console.log(_tmpArray, idx);
        if (idx > -1) _tmpArray[idx] = val;
        else _tmpArray.push(val);
        _tmpArray.sort((a, b) => Number(a.target > Number(b.target) ? 1 : -1));
        setInstruments(_tmpArray);
      };

      await ig.getSocketData(data.accountId, epics, callbackAccount, callbackOpenPosition, callbackEpic);
    };
    fetchData();
  }, [data.accountId, connected, ig.cst]);
  if (!connected)
    return (
      <div className="App">
        <Settings settings={settings} setSettings={setSettings} ig={ig} />
      </div>
    );

  return (
    <div className="App">
      <Left ig={ig} accounts={accounts} data={data} positions={positions} instruments={instruments} />
    </div>
  );
}

export default App;
