const SETTINGS = "settings";

export const setStorageData = (key, value) => {
	window.localStorage.setItem(key, value);
};

export const getStorageData = key => {
	return window.localStorage.getItem(key);
};

export const saveSettings = values => {
	setStorageData("settings", JSON.stringify(values));
};
export const getSettings = () => {
	return JSON.parse(getStorageData("settings"));
};
