import { Button, Grid, TextField } from "@mui/material";
import React from "react";
import IG from "../lib/ig";
import { saveSettings } from "../lib/storage";

export default function Settings({ settings, setSettings, ig }) {
	const check = async () => {
		try {
			const connect = await ig.connect(settings);
			saveSettings(settings);
		} catch (error) {
			console.error(error);
		}
	};
	return (
		<Grid
			container
			direction={"column"}
			justifyContent={"center"}
			alignItems={"center"}
		>
			<TextField
				value={settings.username}
				label='Username'
				margin='normal'
				onChange={e => setSettings({ ...settings, username: e.target.value })}
			/>
			<TextField
				value={settings.password}
				label='Password'
				type='password'
				margin='normal'
				onChange={e => setSettings({ ...settings, password: e.target.value })}
			/>
			<TextField
				value={settings.api}
				label='Api Token'
				type='password'
				margin='normal'
				onChange={e => setSettings({ ...settings, api: e.target.value })}
			/>
			<Button variant='contained' onClick={check}>
				Salva
			</Button>
		</Grid>
	);
}
